<template>
  <div class="container">
    <div class="header-container">
    <header>
    <van-nav-bar
        title="信息内容"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onBack"
    />
    </header>
    </div>
    <div class="section-box">
      <div class="info" v-if="!isError">
        <div class="title">{{data.infoTitle ? data.infoTitle : data.info_title}}</div>
        <div class="time">【{{data.orgCode ? data.orgCode==0 ?'昆明自来水集团': data.orgCode==1 ? '通用水务':'清源自来水' : data.org_code==0 ?'昆明自来水集团': data.org_code==1 ? '通用水务':'清源自来水' }}】 {{data.createTime ? data.createTime+'发布':data.create_time+"发布"}}</div>
        <van-cell-group v-if="menuId == '402891816a5fbdb3016a5fd0acb60018'">
          <van-cell title="停水类别：" :value="data.stopType == 0 ? '计划性停水': '突发性停水' " />
          <van-cell title="停水时间：" :value="data.startTime" />
          <van-cell title="复水时间：" :value="data.endTime" />
          <van-cell title="停水地点：" :label="data.address" />
          <van-cell title="停水范围：" :label="data.area" />
          <van-cell title="停水原因：" :label="data.reason" />
          <van-cell title="停水管径：" :label="data.diameter" />
        </van-cell-group>
        <div class="content" v-if="menuId != '402891816a5fbdb3016a5fd0acb60018'" v-html="data.infoContent"></div>
      </div>
      <van-empty v-if="isError" :description="errorMsg" />
    </div>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import Loading from "../../components/logding";
import {findInfo} from "../../api/info";

export default {
  name: "standard",
  components: { Loading },
  data(){
    return{
      isShow:true,
      lodingTxt:'加载中...',
      errorMsg:'',
      isError:false,
      id:this.$route.query.id,
      menuId: this.$route.query.menuId,
      data:{}
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo(){
      const that = this
      that.isShow = true
      findInfo(that.id, that.menuId).then(response => {
        that.isShow = false
        console.log(response)
        if (response.code == process.env.VUE_APP_SUCCESS_CODE){
          that.data = response.data
        }else{
          that.isError = true
          that.errorMsg = response.message
        }
      }).catch(() => {
        that.isShow = false
        that.isError = true
        that.errorMsg = '请求网络出错，请稍后再试!'
      })
    },
    onBack : function (){
      this.$router.go(-1);
    }
  }

}
</script>

<style scoped>
.info{
  padding: 10px; line-height: 30px; width: auto;
}
.info .title{font-size: 16px; font-weight: bold;text-align: center;color: black;}
.info .time{color:lightgrey;text-align: center;font-size: 12px;}
.info .content{color: #333333;}
</style>