import request from '../utils/request'
import qs from 'qs'

/**
 * 获取列表
 * @param page
 * @param pageSize
 * @param id
 * @returns {*}
 */
export function queryList(page,pageSize,id){
    return request({
        url: 'info/list.do',
        method: 'post',
        data: qs.stringify({page:page, pageSize:pageSize,id:id})
    })
}

/**
 * 获取详细
 * @param id
 * @returns {*}
 */
export function findInfo(id,menuId){
    return request({
        url: 'info/getInfo.do',
        method: 'post',
        data: qs.stringify({id:id,menuId:menuId})
    })
}